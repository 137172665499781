import { Outlet, useLocation } from 'react-router-dom'
import FeedbackButton from 'components/FeedbackButton/FeedbackButton'
import { Helmet } from 'react-helmet-async'
import { useAtomValue } from 'jotai'
import { titleAtom } from 'utils/atoms'

const Layout = () => {
    const title = useAtomValue(titleAtom)
    const location = useLocation()
    const isHomePage = location.pathname === '/franchise'
    const isSeekerHomePage = location.pathname === '/'
    // const { brand, franchise, property, page, collection } = useParams()
    // const pageTitle =
    //     collection || page || property || franchise || brand
    //         ? `${
    //               collection || page || property || franchise || brand
    //           } | Franchise Hub`
    //         : 'Franchise Hub'
    const pageTitle = 'Seeker - Warner Bros Discovery';

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Outlet />
            <FeedbackButton />
        </>
    )
}

export default Layout
